:root {
  --window-width: 100vw;
  --window-height: 100vh;
}

html {
  height: 100%;
}

body {
  height: calc(100% - (#{$padding}*2 + #{$h1-font-size}em));
}

a {
  color: inherit;

  &.link--not-underlined {
    text-decoration: none;
  }
}
