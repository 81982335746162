#qr-reader {
  position: relative;
  height: 100%;
  overflow-y: hidden;

  .page-content__container {
    min-height: auto;
    overflow-x: hidden;
  }
}

.qr-reader__camera {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--window-height) - (#{$padding}*2 + #{$h1-font-size}rem) - (#{$padding}*2 + #{$h1-font-size}rem * 2));
  background-color: black;
  overflow: hidden;
}

#qr-reader-canvas {
  // position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc( (#{$padding}*2 + #{$h1-font-size}rem * 2) * -1);
  background-color: rgba(0,0,0,0.8);
  transition: opacity 0.3s ease;
  opacity: 0;

  &.qr-reader__loading--active {
    opacity: 1;
  }
}

.qr-check-ins {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-qrcode-dark;
  transform: translateY(calc(var(--window-height) - (#{$padding}*2 + #{$h1-font-size}rem) - (#{$padding}*2 + #{$h1-font-size}rem * 2)));

  body.doc-ready & {
    transition: transform 0.5s ease;
  }

  &.qr-check-ins--active {
    overflow-y: auto;
    transform: translateY(0);
  }
}

.qr-check-ins__toggle {
  text-align: center;
  padding-top: calc(#{$h1-font-size}rem * .8);
  padding-bottom: 0;
  margin-top: $padding;
  cursor: pointer;

  body.doc-ready & {
    transition: padding-top 0.5s ease, padding-bottom 0.5s ease;
  }

  &:before {
    content: '';
    font-weight: bold;
    position: absolute;
    top: calc(#{$padding}* 0.8);
    left: 50%;
    width: 0.7em;
    height: 0.7em;
    transform: translateX(-50%) rotate(90deg);
    background-image: url('../img/arrow-slide.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .qr-check-ins--active & {
    padding-top: 0;
    padding-bottom: calc(#{$h1-font-size}rem * .8);

    &:before {
      top: calc(1em * 1.7);
      transform: translateX(-50%) rotate(-90deg);
    }
  }
}

.qr-check-ins__progress-bar {
  box-sizing: border-box;
  border-radius: 2em;
  background-color: $color-qrcode-darker;
  height: 8px;
  width: 100%;
  position: relative;
  margin-bottom: calc(1.6rem * 2);
  margin-top: 2.8rem;

  @media only screen and (min-width: 600px) {
    height: 15px;
  }

  .qr-check-ins__progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(math.div(100%, 75) * 0);
    border-radius: 2em;
    background-color: $color-white;
  }
}

$reward-point-size-mobile: 30px;
$reward-point-size: 40px;
.qr-check-ins__progress-bar-reward {
  border-radius: 50%;
  width: $reward-point-size-mobile;
  height: $reward-point-size-mobile;
  position: absolute;
  background-color: $color-qrcode-darker;
  top: calc( math.div(#{$reward-point-size-mobile}, -2.5) );
  left: calc(math.div(100%, 75) * 0 - math.div(#{$reward-point-size-mobile}, 2));
  z-index: 2;

  @media only screen and (min-width: 600px) {
    width: $reward-point-size;
    height: $reward-point-size;
    top: calc( math.div(#{$reward-point-size}, -3) );
    left: calc(math.div(100%, 75) * 0 - math.div(#{$reward-point-size}, 2));
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  span {
    position: absolute;
    bottom: -1.6em;
    left: calc( math.div(7em, -2) - math.div(#{$reward-point-size-mobile}, -2) );
    font-size: 0.8em;
    font-weight: bold;
    width: 7em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    // background-color: pink;
    @media only screen and (min-width: 600px) {
      left: calc( math.div(7em, -2) - math.div(#{$reward-point-size}, -2) );
    }

  }
}

span.qr-check-ins__progress-bar-reward-num {
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: $reward-point-size-mobile;
  height: $reward-point-size-mobile;
  vertical-align: middle;
  line-height: 2.3;
  color: $color-qrcode;
  padding-top: 0.05em;

  @media only screen and (min-width: 600px) {
    font-size: 1rem;
    line-height: 2.3;
    width: $reward-point-size;
    height: $reward-point-size;
  }
}


[data-stage="0"] {
  .qr-check-ins__progress-bar-reward--1 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--2 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--3 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--4 svg *:not(.reward-bg) {
    fill: $color-qrcode-dark !important;
  }
  .qr-check-ins__progress-bar-reward--1 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--2 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--3 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
    fill: none !important;
  }
  .qr-check-ins__progress-bar-reward--4 svg  * {
    stroke: none;
  }
}

[data-stage="35"] {
  .qr-check-ins__progress-bar-reward--2 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--3 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--4 svg *:not(.reward-bg) {
    fill: $color-qrcode-dark !important;
  }
  .qr-check-ins__progress-bar-reward--2 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--3 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
    fill: none !important;
  }
  .qr-check-ins__progress-bar-reward--4 svg  * {
    stroke: none;
  }
}

[data-stage="50"] {
  .qr-check-ins__progress-bar-reward--3 svg *:not(.reward-bg),
  .qr-check-ins__progress-bar-reward--4 svg *:not(.reward-bg) {
    fill: $color-qrcode-dark-2 !important;
  }
  .qr-check-ins__progress-bar-reward--3 svg .reward-bg,
  .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
    fill: none !important;
  }
  .qr-check-ins__progress-bar-reward--4 svg  * {
    stroke: none;
  }
}

[data-stage="65"] {
  .qr-check-ins__progress-bar-reward--4 svg *:not(.reward-bg) {
    fill: $color-qrcode-dark-2 !important;
  }
  .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
    fill: none !important;
  }
  .qr-check-ins__progress-bar-reward--4 svg  * {
    stroke: none;
  }
}

.qr-check-ins__progress-bar-reward--0 {
  left:  calc(math.div(100%, 75) * 0 - math.div(#{$reward-point-size-mobile}, 3));

  @media only screen and (min-width: 600px) {
    left:  calc(math.div(100%, 75) * 0 - math.div(#{$reward-point-size}, 3));
  }

  [data-stage="0"] &,
  [data-stage="35"] &,
  [data-stage="50"] &,
  [data-stage="65"] &,
  [data-stage="75"] & {
    background-color: $color-white;

    svg * {
      fill: initial !important;
    }
  }
}

.qr-check-ins__progress-bar-reward--1 {
  left:  calc(math.div(100%, 75) * 35 - math.div(#{$reward-point-size-mobile}, 2));

  @media only screen and (min-width: 600px) {
    left:  calc(math.div(100%, 75) * 35 - math.div(#{$reward-point-size}, 2));
  }

  [data-stage="35"] &,
  [data-stage="50"] &,
  [data-stage="65"] &,
  [data-stage="75"] & {
    background-color: $color-white;
  }
}

.qr-check-ins__progress-bar-reward--2 {
  left:  calc(math.div(100%, 75) * 50 - math.div(#{$reward-point-size-mobile}, 2));

  @media only screen and (min-width: 600px) {
    left:  calc(math.div(100%, 75) * 50 - math.div(#{$reward-point-size}, 2));
  }

  [data-stage="50"] &,
  [data-stage="65"] &,
  [data-stage="75"] & {
    background-color: $color-white;
  }
}

.qr-check-ins__progress-bar-reward--3 {
  left:  calc(math.div(100%, 75) * 65 - math.div(#{$reward-point-size-mobile}, 2));

  @media only screen and (min-width: 600px) {
    left:  calc(math.div(100%, 75) * 65 - math.div(#{$reward-point-size}, 2));
  }

  [data-stage="65"] &,
  [data-stage="75"] & {
    background-color: $color-white;
  }
}

.qr-check-ins__progress-bar-reward--4 {
  left:  calc(math.div(100%, 75) * 75 - math.div(#{$reward-point-size-mobile}, 1.5));

  @media only screen and (min-width: 600px) {
    left:  calc(math.div(100%, 75) * 75 - math.div(#{$reward-point-size}, 1.5));
  }

  [data-stage="75"] & {
    background-color: $color-white;
  }

  svg {
    width: 55%;
    margin-left: calc((100% - 55%) / 2 + 2%);

    * {
      stroke: black;
      stroke-width: 5px;
    }
  }
}


.qr-check-ins__code {
  margin-top: #{$h1-font-size}rem;
  margin-bottom: #{$h1-font-size}rem;
  text-align: center;

  h1 {
    color: $color-black;
    display: inline-block;
    padding: 0.5em 0.8em;
    width: auto;
    background-color: $color-white;
    border-radius: 8px;
  }
}
