.bg-color--about {
  background-color: $color-about;
}
.bg-color--about-dark {
  background-color: $color-about-dark;
}

.bg-color--map {
  background-color: $color-map;
}

.bg-color--whats-on {
  background-color: $color-whatson;
}
.bg-color--whats-on-dark {
  background-color: $color-whatson-dark;
}

.bg-color--qr-reader {
  background-color: $color-qrcode;
}
.bg-color--qr-reader-dark {
  background-color: $color-qrcode-dark;
}


.page-content {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}

.page-content__container {
  padding: $padding;
  padding-top: $padding * 2;
  min-height: 100%;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;

  * {
    color: $color-white;
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 1.5em;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: 1em;
  }
}
