#homepage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $color-about;
  overflow: hidden;

  &, & * {
    color: $color-white;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
  }
}


.hero {
  min-height: calc(var(--window-height) - (#{$padding}*2 + 2em)*4 );
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: $color-white;
  background-image:
    linear-gradient(90deg, transparent 99%, rgba(29,29,27,0.2) 99%),
    linear-gradient(transparent 99%,rgba(29,29,27,0.2) 99%);
  background-size: calc(100vw/6) calc(100vw/6);
  background-repeat: repeat;

  @media only screen and (min-width: 600px) {
    background-size: calc(100vw/12) calc(100vw/12);
  }
}

.hero__img {
  position: relative;
  width: 100%;
  max-width: 330px;

  @media only screen and (min-width: 1024px) {
    max-width: 500px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: calc(var(--window-height) - 4*(2em + #{$padding}*2) - #{$padding}*2);
    object-fit: contain;
    object-position: left top;
  }
}

.hero__img--info {
  margin-top: 20px;
  margin-right: 0;
  margin-left: auto;
  z-index: 2;

  @media (max-aspect-ratio: 2/1) {
    position: absolute;
    right: 0;
    top: 180px;
  }

  @media (max-aspect-ratio: 2/1) and (min-width: 1024px)  {
    top: 70px;
  }
}

.hero__img--logo {
  margin-top: 35px;
}

.hero__img--top-aligned {
  width: 100%;
  max-width: initial;
  height: 35px;
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: left top;
}

.hero__img--bottom-aligned {
  max-width: initial;
  height: 8vh;
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: left bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media only screen and (min-width: 600px) {
    height: 10.45vh;
  }

  img {
    margin-bottom: -1px;
  }
}

.nav-menu {
  flex-shrink: 1;
  z-index: 2;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;


    li + li {
      padding-top: 0;
    }
  }
}

.nav-menu__opt {
  @extend .h1--heading;

  a {
    text-decoration: none;
    color: inherit;
    line-height: inherit;
    padding: $padding;
    width: calc(100% - #{$padding}*2);
    display: block;
    color: $color-white;
    // max-width: 900px;
    // margin-left: auto;
    // margin-right: auto;

    // @media only screen and (min-width: 900px) {
    //   text-align: center;
    // }
  }
}


.feed-slideshow {
  position: absolute;
  inset: 0;
  max-height: 50vh;
  display: block;

  [data-glide-slideshow] {
    background: transparent;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .glide__slide {
    padding: $padding*2;
    max-height: 100%;
  }
}

.feed-card {
  background-color: white;
  padding: $padding;
  border-radius: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 8px 0px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  * {
    color: $color-black !important;
  }


  img {
    border-radius: 1em;
    border: 1px solid rgba(100, 100, 111, 0.2);
    max-height: 45vh;
    width: 100%;
    object-fit: cover;
  }

  small {
    font-size: 0.7em;
  }

  a {
    display: block;
    margin-top: 0.5em;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  &__local-icone {
    width: 1.1em;
    height: 1.1em;
    transform: translateY(0.15em);
    background-image: url('../img/icon-map-green.svg');
  }
}
