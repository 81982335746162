.nei-accordion {
  overflow: hidden;
  height: auto;
  margin-bottom: 1em;
}

[data-toggle-accordion] {
  &:hover {
    cursor: pointer;
  }
}

[data-toggle-accordion]:after {
  content: '';
  width: 0.8em;
  height: 0.8em;
  transform: rotate(-90deg);
  background-image: url('../img/arrow-slide.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.35em;

  .tab--active & {
    transform: rotate(90deg);
    margin-top: 0.2em;
  }
}
