.nei-button {
  outline: 0;
  border: 0;
  border-radius: 1em;
  background-color: $color-white;
  font-size: 1.5em;
  padding-left: .5em;
  padding-right: .5em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: calc(2em - #{$padding});
  color: $color-black;

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/static/img/arrow-btn.svg');
    width: 0.7em;
    height: 0.7em;
    margin-top: 0.2em;
    margin-right: 0.3em;
    transition: all ease 0.2s;
  }

  &:hover {
    // background-color: $color-white;

    &:before {
      margin-left: 0.15em;
      margin-right: 0.15em;
    }
  }
}
