#activity-qrcode {
  display: none;
  color: black;

  &.page-content {
    min-height: initial;
  }

  &.show {
    display: block;
  }

  & .page-content__container {
    padding: 15px;
  }

  img {
    width: 100%;
  }

  .heading + & {
    @extend %heading-margin;
  }

  &:not(.show) + .page-content {
    @extend %heading-margin;
  }

  * {
    color: $color-black;
  }
}

.activity-qrcode__construction-banner {
  height: 30px;
  background: repeating-linear-gradient(
    -45deg,
    #010203,
    #010203 20px,
    #f6b511 20px,
    #f6b511 40px
  );
}

 #activity-qrcode .activity-qrcode__download-button {
  padding: 0.8em;
  display: block;
  background-color: black;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  text-align: center;

  &, * {
    color: white;
  }
}
