html {
  font-size: #{$base-font-size}px;
  color: $color-white;
}

* {
  font-family: "Bricolage Grotesque", system-ui;
  font-size: 1em;
  line-height: 1.36;
}

p + p {
  padding-top: 1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  font-size: #{$h1-font-size}em;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "Unbounded", sans-serif;
}

.h1--heading {
  font-size: #{$h1-font-size}em;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Unbounded", sans-serif;
}

h2 {
  font-size: #{$h1-font-size}em;
  font-weight: 800;
  line-height: 1.2;
  font-family: "Unbounded", sans-serif;
}

h4 {
  font-size: #{$h4-font-size}em;
  font-weight: 800;
  line-height: 1.2;
  font-family: "Unbounded", sans-serif;
}

h5 {
  font-size: #{$h4-font-size}em;
  line-height: 1.2;
}

li + li {
  padding-top: 0.5em;
}
