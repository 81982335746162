*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  background-color: #fff;
  border: solid;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.glide__track, .glide__slides, .glide__slide {
  width: 100%;
  height: 100%;
}

.glide__slide {
  padding-top: 0;
  transform: translate3d(0, 0, 0);
}

[data-glide-captions] > * {
  display: none;
}

[data-glide-captions] > .active {
  display: block;
}

.glide__arrow {
  box-shadow: none;
  text-shadow: none;
  color: #f2ede6;
  border: 0;
  border-radius: 0;
  height: 1em;
  margin: 0;
  padding: 0;
  font-size: 1.7em;
  font-weight: normal;
  left: 15px;
}

.glide__arrow svg {
  vertical-align: initial;
  height: 100%;
}

.glide__arrow svg polygon {
  fill: #f2ede6;
  opacity: .8;
}

.glide__arrow--next {
  left: initial;
  right: 15px;
}

i.fas, i.fab {
  margin-left: .5em;
  margin-right: .2em;
  font-size: .9em;
}

i.fas.fa-map-marker-alt {
  margin-top: .15em;
  margin-left: 0;
  margin-right: .5em;
}

@media only screen and (min-width: 600px) {
  .notie-container {
    font-size: 1.4rem;
  }
}

.notie-background-success {
  background-color: #fcb315;
}

.notie-background-warning {
  background-color: #3fb984;
}

.notie-background-error {
  background-color: #2e2e88;
}

.notie-background-info {
  background-color: #18bbd0;
}

.pswp__button--arrow--left:before {
  width: .7em;
  left: 10px;
  background-color: #0000 !important;
  background-image: url("arrow-slide-outline.c174a47f.svg") !important;
  background-position: center !important;
  background-size: contain !important;
}

.pswp__button--arrow--right:before {
  width: .7em;
  right: 10px;
  background-color: #0000 !important;
  background-image: url("arrow-slide-outline--right.7c118918.svg") !important;
  background-position: center !important;
  background-size: contain !important;
}

.pswp__counter {
  color: #000;
  font-weight: bold;
}

.pswp__button:not(.pswp__button--arrow--left):not(.pswp__button--arrow--right) {
  background-image: url("photoswipe-black-default-skin-glow.efdb8251.png");
}

.pswp--visible .pswp__bg {
  opacity: .25 !important;
}

:root {
  --window-width: 100vw;
  --window-height: 100vh;
}

html {
  height: 100%;
}

body {
  height: calc(100% + -30px - 2em);
}

a {
  color: inherit;
}

a.link--not-underlined {
  text-decoration: none;
}

html {
  color: #f2ede6;
  font-size: 17px;
}

* {
  font-family: Bricolage Grotesque, system-ui;
  font-size: 1em;
  line-height: 1.36;
}

p + p {
  padding-top: 1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  text-transform: uppercase;
  font-family: Unbounded, sans-serif;
  font-size: 2em;
  font-weight: 800;
  line-height: 1.2;
}

.h1--heading, .nav-menu__opt, .heading__txt {
  text-transform: uppercase;
  font-family: Unbounded, sans-serif;
  font-size: 2em;
  font-weight: 800;
  line-height: 1;
}

h2 {
  font-family: Unbounded, sans-serif;
  font-size: 2em;
  font-weight: 800;
  line-height: 1.2;
}

h4 {
  font-family: Unbounded, sans-serif;
  font-size: 1.29412em;
  font-weight: 800;
  line-height: 1.2;
}

h5 {
  font-size: 1.29412em;
  line-height: 1.2;
}

li + li {
  padding-top: .5em;
}

.bg-color--about {
  background-color: #2e2e88;
}

.bg-color--about-dark {
  background-color: #29297a;
}

.bg-color--map {
  background-color: #18bbd0;
}

.bg-color--whats-on {
  background-color: #fcb315;
}

.bg-color--whats-on-dark {
  background-color: #e2a112;
}

.bg-color--qr-reader {
  background-color: #3fb984;
}

.bg-color--qr-reader-dark {
  background-color: #38a676;
}

.page-content {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}

.page-content__container {
  max-width: 992px;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 15px 15px;
}

.page-content__container * {
  color: #f2ede6;
}

.page-content__container h1:not(:first-child), .page-content__container h2:not(:first-child), .page-content__container h3:not(:first-child), .page-content__container h4:not(:first-child), .page-content__container h5:not(:first-child), .page-content__container h6:not(:first-child) {
  margin-top: 1.5em;
}

.page-content__container h1:not(:last-child), .page-content__container h2:not(:last-child), .page-content__container h3:not(:last-child), .page-content__container h4:not(:last-child), .page-content__container h5:not(:last-child), .page-content__container h6:not(:last-child) {
  margin-bottom: 1em;
}

.heading {
  z-index: 2;
  width: 100%;
  padding: 15px;
  position: fixed;
  top: 0;
}

.heading, .heading * {
  color: #f2ede6;
  font-family: Unbounded, sans-serif;
  font-weight: bold;
}

.heading + .page-content, #activity-qrcode:not(.show) + .page-content, .heading + #activity-qrcode {
  margin-top: calc(30px + 2em);
}

.heading__txt {
  text-align: center;
  width: calc(100% - 30px);
}

.heading__back-home {
  cursor: pointer;
  width: 1.8em;
  height: 1.8em;
  position: absolute;
  top: calc(15px + .1em);
  left: 15px;
}

.nei-button {
  color: #1d140c;
  background-color: #f2ede6;
  border: 0;
  border-radius: 1em;
  outline: 0;
  margin: 2em auto calc(2em - 15px);
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1.5em;
  display: block;
}

.nei-button:before {
  content: "";
  background-image: url("arrow-btn.0dbe2759.svg");
  width: .7em;
  height: .7em;
  margin-top: .2em;
  margin-right: .3em;
  transition: all .2s;
  display: inline-block;
}

.nei-button:hover:before {
  margin-left: .15em;
  margin-right: .15em;
}

.nei-accordion {
  height: auto;
  margin-bottom: 1em;
  overflow: hidden;
}

[data-toggle-accordion]:hover {
  cursor: pointer;
}

[data-toggle-accordion]:after {
  content: "";
  background-image: url("arrow-slide.6d4cee2e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  margin-top: .35em;
  margin-left: .5em;
  position: absolute;
  transform: rotate(-90deg);
}

.tab--active [data-toggle-accordion]:after {
  margin-top: .2em;
  transform: rotate(90deg);
}

#activity-qrcode {
  color: #000;
  display: none;
}

#activity-qrcode.page-content {
  min-height: initial;
}

#activity-qrcode.show {
  display: block;
}

#activity-qrcode .page-content__container {
  padding: 15px;
}

#activity-qrcode img {
  width: 100%;
}

#activity-qrcode * {
  color: #1d140c;
}

.activity-qrcode__construction-banner {
  background: repeating-linear-gradient(-45deg, #010203, #010203 20px, #f6b511 20px 40px);
  height: 30px;
}

#activity-qrcode .activity-qrcode__download-button {
  text-transform: uppercase;
  text-align: center;
  background-color: #000;
  padding: .8em;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

#activity-qrcode .activity-qrcode__download-button, #activity-qrcode .activity-qrcode__download-button * {
  color: #fff;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .95em;
  height: .95em;
  display: inline-block;
}

.icon--close {
  background-image: url("icon-close.2206130f.svg");
}

.nei-slideshow {
  margin-top: 30px;
}

[data-glide-slideshow] {
  width: calc(100% + 30px);
  height: 66.6667vw;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 992px) {
  [data-glide-slideshow] {
    width: 100%;
    height: 661.333px;
    margin-left: 0;
    margin-right: 0;
  }
}

[data-glide-slideshow] img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.glide__slides {
  margin-top: 0;
}

[data-glide-captions] {
  margin-top: 7.5px;
  font-size: .8em;
}

.loading-icon {
  width: 80px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.loading-icon svg {
  transform-origin: center;
  width: 100%;
  height: auto;
  animation: 4s linear infinite rotating;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#homepage {
  background-color: #2e2e88;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#homepage, #homepage * {
  color: #f2ede6;
  font-family: Unbounded, sans-serif;
  font-weight: bold;
}

.hero {
  min-height: calc(var(--window-height)  - (15px * 2 + 2em) * 4);
  background-color: #f2ede6;
  background-image: linear-gradient(90deg, #0000 99%, #1d1d1b33 99%), linear-gradient(#0000 99%, #1d1d1b33 99%);
  background-repeat: repeat;
  background-size: 16.6667vw 16.6667vw;
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden auto;
}

@media only screen and (min-width: 600px) {
  .hero {
    background-size: 8.33333vw 8.33333vw;
  }
}

.hero__img {
  width: 100%;
  max-width: 330px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .hero__img {
    max-width: 500px;
  }
}

.hero__img img {
  max-height: calc(var(--window-height)  - 4 * (2em + 15px * 2)  - 15px * 2);
  object-fit: contain;
  object-position: left top;
  width: 100%;
  height: 100%;
}

.hero__img--info {
  z-index: 2;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 0;
}

@media (max-aspect-ratio: 2) {
  .hero__img--info {
    position: absolute;
    top: 180px;
    right: 0;
  }
}

@media (max-aspect-ratio: 2) and (min-width: 1024px) {
  .hero__img--info {
    top: 70px;
  }
}

.hero__img--logo {
  margin-top: 35px;
}

.hero__img--top-aligned {
  max-width: initial;
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: auto 100%;
  width: 100%;
  height: 35px;
}

.hero__img--bottom-aligned {
  max-width: initial;
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: auto 100%;
  height: 8vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (min-width: 600px) {
  .hero__img--bottom-aligned {
    height: 10.45vh;
  }
}

.hero__img--bottom-aligned img {
  margin-bottom: -1px;
}

.nav-menu {
  z-index: 2;
  flex-shrink: 1;
}

.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul li + li {
  padding-top: 0;
}

.nav-menu__opt a {
  color: #f2ede6;
  line-height: inherit;
  width: calc(100% - 30px);
  padding: 15px;
  text-decoration: none;
  display: block;
}

.feed-slideshow {
  max-height: 50vh;
  display: block;
  position: absolute;
  inset: 0;
}

.feed-slideshow [data-glide-slideshow] {
  background: none;
  width: 100%;
  height: auto;
  margin: 0;
}

.feed-slideshow .glide__slide {
  max-height: 100%;
  padding: 30px;
}

.feed-card {
  background-color: #fff;
  border-radius: 1em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  box-shadow: 0 7px 8px #64646f33;
}

.feed-card * {
  color: #1d140c !important;
}

.feed-card img {
  object-fit: cover;
  border: 1px solid #64646f33;
  border-radius: 1em;
  width: 100%;
  max-height: 45vh;
}

.feed-card small {
  font-size: .7em;
}

.feed-card a {
  margin-top: .5em;
  text-decoration: none;
  display: block;
}

.feed-card a:hover {
  text-decoration: none;
}

.feed-card__local-icone {
  background-image: url("icon-map-green.5d7c9ec2.svg");
  width: 1.1em;
  height: 1.1em;
  transform: translateY(.15em);
}

.nei-map {
  position: absolute;
  inset: 0;
}

.event {
  padding-bottom: 30px;
}

.event:not(:first-child) {
  margin-top: 30px;
}

.event:not(:last-child) {
  border-bottom: 1px solid #f2ede6;
}

.events-filter {
  background-color: #c98f10;
  max-height: 0;
  position: relative;
  overflow-y: hidden;
}

.events-filter.events-filter--open {
  max-height: calc(4.65882em + 30px);
}

.events-filter .icon--close {
  width: 2em;
  height: 2em;
  position: absolute;
  top: 12px;
  right: 10px;
}

.events-filter .icon--close:hover {
  cursor: pointer;
}

.events-filter__content {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  position: relative;
}

.event-local-details {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
}

.event-local-details h4, .event-local-details h5, .event-local-details p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.activity-photo-upload {
  text-align: center;
}

.activity-photo-upload__button {
  display: inline-block;
}

#qr-reader {
  height: 100%;
  position: relative;
  overflow-y: hidden;
}

#qr-reader .page-content__container {
  min-height: auto;
  overflow-x: hidden;
}

.qr-reader__camera {
  height: calc(var(--window-height)  - (15px * 2 + 2rem)  - (15px * 2 + 2rem * 2));
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#qr-reader-canvas {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.qr-reader__loading {
  opacity: 0;
  background-color: #000c;
  transition: opacity .3s;
  position: absolute;
  inset: 0 0 calc(-30px - 4rem);
}

.qr-reader__loading.qr-reader__loading--active {
  opacity: 1;
}

.qr-check-ins {
  transform: translateY(calc(var(--window-height)  - (15px * 2 + 2rem)  - (15px * 2 + 2rem * 2)));
  background-color: #38a676;
  position: absolute;
  inset: 0;
}

body.doc-ready .qr-check-ins {
  transition: transform .5s;
}

.qr-check-ins.qr-check-ins--active {
  overflow-y: auto;
  transform: translateY(0);
}

.qr-check-ins__toggle {
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
  padding-top: 1.6rem;
  padding-bottom: 0;
}

body.doc-ready .qr-check-ins__toggle {
  transition: padding-top .5s, padding-bottom .5s;
}

.qr-check-ins__toggle:before {
  content: "";
  background-image: url("arrow-slide.6d4cee2e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .7em;
  height: .7em;
  font-weight: bold;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%)rotate(90deg);
}

.qr-check-ins--active .qr-check-ins__toggle {
  padding-top: 0;
  padding-bottom: 1.6rem;
}

.qr-check-ins--active .qr-check-ins__toggle:before {
  top: 1.7em;
  transform: translateX(-50%)rotate(-90deg);
}

.qr-check-ins__progress-bar {
  box-sizing: border-box;
  background-color: #2c815c;
  border-radius: 2em;
  width: 100%;
  height: 8px;
  margin-top: 2.8rem;
  margin-bottom: 3.2rem;
  position: relative;
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar {
    height: 15px;
  }
}

.qr-check-ins__progress-bar .qr-check-ins__progress-bar-fill {
  background-color: #f2ede6;
  border-radius: 2em;
  width: 0%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.qr-check-ins__progress-bar-reward {
  z-index: 2;
  background-color: #2c815c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -12px;
  left: -15px;
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward {
    width: 40px;
    height: 40px;
    top: -13.3333px;
    left: -20px;
  }
}

.qr-check-ins__progress-bar-reward svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}

.qr-check-ins__progress-bar-reward span {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 7em;
  font-size: .8em;
  font-weight: bold;
  position: absolute;
  bottom: -1.6em;
  left: calc(15px - 3.5em);
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward span {
    left: calc(20px - 3.5em);
  }
}

span.qr-check-ins__progress-bar-reward-num {
  vertical-align: middle;
  color: #3fb984;
  width: 30px;
  height: 30px;
  padding-top: .05em;
  font-size: .8rem;
  font-weight: bold;
  line-height: 2.3;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 600px) {
  span.qr-check-ins__progress-bar-reward-num {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    line-height: 2.3;
  }
}

[data-stage="0"] .qr-check-ins__progress-bar-reward--1 svg :not(.reward-bg), [data-stage="0"] .qr-check-ins__progress-bar-reward--2 svg :not(.reward-bg), [data-stage="0"] .qr-check-ins__progress-bar-reward--3 svg :not(.reward-bg), [data-stage="0"] .qr-check-ins__progress-bar-reward--4 svg :not(.reward-bg) {
  fill: #38a676 !important;
}

[data-stage="0"] .qr-check-ins__progress-bar-reward--1 svg .reward-bg, [data-stage="0"] .qr-check-ins__progress-bar-reward--2 svg .reward-bg, [data-stage="0"] .qr-check-ins__progress-bar-reward--3 svg .reward-bg, [data-stage="0"] .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
  fill: none !important;
}

[data-stage="0"] .qr-check-ins__progress-bar-reward--4 svg * {
  stroke: none;
}

[data-stage="35"] .qr-check-ins__progress-bar-reward--2 svg :not(.reward-bg), [data-stage="35"] .qr-check-ins__progress-bar-reward--3 svg :not(.reward-bg), [data-stage="35"] .qr-check-ins__progress-bar-reward--4 svg :not(.reward-bg) {
  fill: #38a676 !important;
}

[data-stage="35"] .qr-check-ins__progress-bar-reward--2 svg .reward-bg, [data-stage="35"] .qr-check-ins__progress-bar-reward--3 svg .reward-bg, [data-stage="35"] .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
  fill: none !important;
}

[data-stage="35"] .qr-check-ins__progress-bar-reward--4 svg * {
  stroke: none;
}

[data-stage="50"] .qr-check-ins__progress-bar-reward--3 svg :not(.reward-bg), [data-stage="50"] .qr-check-ins__progress-bar-reward--4 svg :not(.reward-bg) {
  fill: #3fb984 !important;
}

[data-stage="50"] .qr-check-ins__progress-bar-reward--3 svg .reward-bg, [data-stage="50"] .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
  fill: none !important;
}

[data-stage="50"] .qr-check-ins__progress-bar-reward--4 svg * {
  stroke: none;
}

[data-stage="65"] .qr-check-ins__progress-bar-reward--4 svg :not(.reward-bg) {
  fill: #3fb984 !important;
}

[data-stage="65"] .qr-check-ins__progress-bar-reward--4 svg .reward-bg {
  fill: none !important;
}

[data-stage="65"] .qr-check-ins__progress-bar-reward--4 svg * {
  stroke: none;
}

.qr-check-ins__progress-bar-reward--0 {
  left: -10px;
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward--0 {
    left: -13.3333px;
  }
}

[data-stage="0"] .qr-check-ins__progress-bar-reward--0, [data-stage="35"] .qr-check-ins__progress-bar-reward--0, [data-stage="50"] .qr-check-ins__progress-bar-reward--0, [data-stage="65"] .qr-check-ins__progress-bar-reward--0, [data-stage="75"] .qr-check-ins__progress-bar-reward--0 {
  background-color: #f2ede6;
}

[data-stage="0"] .qr-check-ins__progress-bar-reward--0 svg *, [data-stage="35"] .qr-check-ins__progress-bar-reward--0 svg *, [data-stage="50"] .qr-check-ins__progress-bar-reward--0 svg *, [data-stage="65"] .qr-check-ins__progress-bar-reward--0 svg *, [data-stage="75"] .qr-check-ins__progress-bar-reward--0 svg * {
  fill: initial !important;
}

.qr-check-ins__progress-bar-reward--1 {
  left: calc(46.6667% - 15px);
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward--1 {
    left: calc(46.6667% - 20px);
  }
}

[data-stage="35"] .qr-check-ins__progress-bar-reward--1, [data-stage="50"] .qr-check-ins__progress-bar-reward--1, [data-stage="65"] .qr-check-ins__progress-bar-reward--1, [data-stage="75"] .qr-check-ins__progress-bar-reward--1 {
  background-color: #f2ede6;
}

.qr-check-ins__progress-bar-reward--2 {
  left: calc(66.6667% - 15px);
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward--2 {
    left: calc(66.6667% - 20px);
  }
}

[data-stage="50"] .qr-check-ins__progress-bar-reward--2, [data-stage="65"] .qr-check-ins__progress-bar-reward--2, [data-stage="75"] .qr-check-ins__progress-bar-reward--2 {
  background-color: #f2ede6;
}

.qr-check-ins__progress-bar-reward--3 {
  left: calc(86.6667% - 15px);
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward--3 {
    left: calc(86.6667% - 20px);
  }
}

[data-stage="65"] .qr-check-ins__progress-bar-reward--3, [data-stage="75"] .qr-check-ins__progress-bar-reward--3 {
  background-color: #f2ede6;
}

.qr-check-ins__progress-bar-reward--4 {
  left: calc(100% - 20px);
}

@media only screen and (min-width: 600px) {
  .qr-check-ins__progress-bar-reward--4 {
    left: calc(100% - 26.6667px);
  }
}

[data-stage="75"] .qr-check-ins__progress-bar-reward--4 {
  background-color: #f2ede6;
}

.qr-check-ins__progress-bar-reward--4 svg {
  width: 55%;
  margin-left: 24.5%;
}

.qr-check-ins__progress-bar-reward--4 svg * {
  stroke: #000;
  stroke-width: 5px;
}

.qr-check-ins__code {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.qr-check-ins__code h1 {
  color: #1d140c;
  background-color: #f2ede6;
  border-radius: 8px;
  width: auto;
  padding: .5em .8em;
  display: inline-block;
}

/*# sourceMappingURL=index.6390640c.css.map */
