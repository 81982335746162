$base-font-size: 17;
$h1-font-size: math.div(34, $base-font-size);
$h4-font-size: math.div(22, $base-font-size);

$color-about: #2E2E88;
$color-map: #18BBD0;
$color-qrcode: #3FB984;
$color-whatson: #FCB315;
$color-white: #F2EDE6;
$color-black: #1D140C;


$color-about-dark: #29297a;
$color-qrcode-dark: #38a676;
$color-qrcode-dark-2: #3FB984;
$color-whatson-dark: #e2a112;

$color-whatson-darker: #c98f10;
$color-qrcode-darker: #2c815c;

$padding: 15px;
