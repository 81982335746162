.heading {
  position: fixed;
  width: 100%;
  padding: $padding;
  z-index: 2;
  top: 0;

  &, & * {
    color: $color-white;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
  }
}

.heading + .page-content, %heading-margin {
  margin-top: calc(#{$padding}*2 + #{$h1-font-size}em);
}

.heading__txt {
  text-align: center;
  width: calc(100% - #{$padding}*2);
  @extend .h1--heading;
}

.heading__back-home {
  position: absolute;
  left: $padding;
  top: calc(#{$padding} + #{$h1-font-size}em * 0.05);
  width: calc( #{$h1-font-size}em * 0.9);
  height: calc( #{$h1-font-size}em * 0.9);
  cursor: pointer;
}
