@use "sass:math";

// 1. ABSTRACTS
@import 'abstracts/variables';

// 2. VENDORS
@import 'vendors';

// 3. VENDORS EXTENSIONS
@import 'vendor-extensions/glide';
@import 'vendor-extensions/font-awesome';
@import 'vendor-extensions/notie';
@import 'vendor-extensions/pswp';

// 4. BASE
@import 'base/base';
@import 'base/typography';

// 5. LAYOUT
@import 'layout/general';
@import 'layout/heading';

// 6. COMPONENTS
@import 'components/button';
@import 'components/accordion';
@import 'components/activity-qrcode';
@import 'components/icons';
@import 'components/slideshow';
@import 'components/loading-icon';

// 7. PAGES
@import 'pages/index';
@import 'pages/map';
@import 'pages/whats-on';
@import 'pages/qr-reader';
