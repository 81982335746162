.event {
  padding-bottom: $padding * 2;

  &:not(:first-child) {
    margin-top: $padding * 2;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-white;
  }
}

.events-filter {
  position: relative;
  background-color: $color-whatson-darker;
  overflow-y: hidden;
  max-height: 0;

  &.events-filter--open {
    max-height: calc((#{$h4-font-size}em*1.2) * 3 + #{$padding}*2);
  }

  .icon--close {
    width: 2em;
    height: 2em;
    position: absolute;
    top: calc(#{$padding} * 0.8);
    right: calc(#{$padding}/3 * 2);

    &:hover {
      cursor: pointer;
    }
  }
}


.events-filter__content {
  position: relative;
  padding: $padding;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
}

.event-local-details {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;

  h4, h5, p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.activity-photo-upload {
  text-align: center;

  &__button {
    display: inline-block;
  }
}

