.nei-slideshow {
  margin-top: $padding *2;
}

[data-glide-slideshow] {
  // background-color: white;
  height: calc(100vw / 3 * 2);
  margin-left: -$padding;
  margin-right: -$padding;
  width: calc(100% + #{$padding}*2);

  @media only screen and (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: calc(992px / 3 * 2);
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
}

.glide__slides {
  margin-top: 0;
}

[data-glide-captions] {
  margin-top: math.div($padding, 2);
  font-size: 0.8em;
}

