.glide__track,
.glide__slides,
.glide__slide {
  width: 100%;
  height: 100%;
}

.glide__slide {
  padding-top: 0;
  transform: translate3d(0, 0, 0);
}

[data-glide-captions] {
  & > * {
    display: none;

    &.active {
      display: block;
    }
  }
}

.glide__arrow {
  box-shadow: none;
  text-shadow: none;
  color: black;
  border: 0;
  font-size: 1.7em;
  font-weight: normal;
  padding: 0;
  margin: 0;
  left: $padding;
  color: $color-white;
  height: 1em;
  border-radius: 0;

  svg {
    height: 100%;
    vertical-align: initial;

    polygon {
      fill: $color-white;
      opacity: 0.8;
    }
  }

  // &:hover {
  //   svg polygon {
  //     fill: $color-white;
  //   }
  // }
}

.glide__arrow--next {
  left: initial;
  right: $padding;
}
