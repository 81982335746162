i.fas,
i.fab {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-right: 0.2em;
}

i.fas.fa-map-marker-alt {
  margin-left: 0;
  margin-right: 0.5em;
  margin-top: 0.15em;
}
