.notie-container {
  @media only screen and (min-width: 600px) {
    font-size: 1.4rem;
  }
}

.notie-background-info {
  background-color: $color-map;
}

.notie-background-success {
  background-color: $color-whatson;
}

.notie-background-warning {
  background-color: $color-qrcode;
}

.notie-background-error {
  background-color: $color-about;
}

.notie-background-info {
  background-color: $color-map;
}
